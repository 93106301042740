// Auto-generated by https://github.com/react-spring/gltfjsx

import * as THREE from "three";
import React, { useState, useRef, useEffect } from "react";
import { useThree, useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { getMouseDegrees } from "./utils";

function moveJoint(mouse, joint, degreeLimit = 40) {
  let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit);
  joint.rotation.xD = THREE.MathUtils.lerp(
    joint.rotation.xD || 0,
    degrees.y,
    0.1
  );
  joint.rotation.yD = THREE.MathUtils.lerp(
    joint.rotation.yD || 0,
    degrees.x,
    0.1
  );
  joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD);
  joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD);
}

export default function Model({ mouse, ...props }) {
  const { nodes, animations } = useLoader(GLTFLoader, "/Male_Casual.glb");
  const { size } = useThree();
  const [mixer] = useState(() => new THREE.AnimationMixer(nodes.Scene));
  const group = useRef();

  useEffect(
    () => void mixer.clipAction(animations[0], group.current).play(),
    []
  );

  useFrame((state, delta) => {
    // Load in animation (looks left then right)
    const currTime = parseInt(state.clock.elapsedTime, 10);
    if (currTime < 3) {
      const corners = {
        x: currTime < 2 ? size.width / 3 : size.width * (2 / 3),
        y: currTime < 2 ? size.height / 3 : size.height * (2 / 3),
      };
      moveJoint(corners, nodes.Neck);
      moveJoint(corners, nodes.Torso);
      moveJoint(corners, nodes.Head);
    } else {
      // After inital load, model follows mouse
      const mouse = {
        x: size.width / 2 + (state.mouse.x * size.width) / 2,
        y: size.height / 2 + (-state.mouse.y * size.height) / 2,
      };
      mixer.update(delta);
      moveJoint(mouse, nodes.Neck);
      moveJoint(mouse, nodes.Torso);
      moveJoint(mouse, nodes.Head);
    }
  });

  return (
    <group ref={group}>
      <primitive object={nodes.Scene} scale={0.8} position={[0, -2, 7]} />
    </group>
  );
}
