import React, { useRef, Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import ReactDOM from "react-dom";
import Dean from "./modules/Models/Dean";
import Dino from "./modules/Models/Dino";
import Spaceship from "./modules/Models/Spaceship";
import Socials from "./modules/Socials/socials";
import background from "./assets/cool-background.png";
import "./styles.css";
import "./index.css";

function Plane({ ...props }) {
  return (
    <mesh {...props} receiveShadow>
      <planeBufferGeometry args={[500, 500, 1, 1]} />
      <shadowMaterial transparent opacity={0.2} />
    </mesh>
  );
}

function App() {
  const d = 8.25;
  const mouse = useRef({ x: 0, y: 0 });

  return (
    <div className="main-container">
      <img src={background} className="triangle-bg" />
      <h1 className="dean-header">Dean <br/> Diakov</h1>
      <h1 className="dean-header-small">Dean Diakov</h1>
      <Canvas shadows dpr={[1, 1.5]} camera={{ position: [0, 0, 13] }}>
        <hemisphereLight
          groundColor={undefined}
          intensity={0.5}
          position={[0, 50, 10]}
        />
        <directionalLight
          color={"#FDF4DC"}
          position={[-8, 20, 8]}
          shadow-camera-left={d * -1}
          shadow-camera-bottom={d * -1}
          shadow-camera-right={d}
          shadow-camera-top={d}
          shadow-camera-near={0.1}
          shadow-camera-far={1500}
          castShadow
        />
        <mesh position={[0, 0, -10]}>
          <circleBufferGeometry args={[8, 64]} />
          <meshBasicMaterial color="white" />
        </mesh>
        <Plane rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -10, 0]} />
        <Suspense fallback={null}>
        <Spaceship/>
          <Dean
            mouse={mouse}
            position={[0, -10, 0]}
          />
          <Dino/>

        </Suspense>
      </Canvas>
      <Socials/>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
