import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithubSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

function Socials() {
  return (
    <div className="socials-container">
      <a href="https://www.linkedin.com/in/deandiakov/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} className="socials-icon" />
      </a>
      <a href="https://github.com/deandiakov" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithubSquare} className="socials-icon" />
      </a>
      <a href="https://www.instagram.com/deanluminati/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagramSquare} className="socials-icon" />
      </a>
      <a href="https://apps.apple.com/us/app/clonr/id1462586813" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faPlusSquare} className="socials-icon" />
      </a>
    </div>
  );
}

export default Socials;
