// Auto-generated by https://github.com/react-spring/gltfjsx

import * as THREE from "three";
import React, { useState, useRef, useEffect } from "react";
import { useThree, useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default function Model({ ...props }) {
  const { nodes, animations } = useLoader(GLTFLoader, "/Spaceship2.glb");
  const [mixer] = useState(() => new THREE.AnimationMixer(nodes.Scene));
  const group = useRef();
  const [reverse, setReverse] = useState(false);

  useFrame((state, delta) => {
    if (nodes.Scene.position.z > 11) {
      setReverse(true);
    }

    if (nodes.Scene.position.z < -11) {
      setReverse(false);
    }

    if (reverse) {
      nodes.Scene.rotation.y = 3.1;
      nodes.Scene.position.z -= delta;
      nodes.Scene.position.x = 6;
    } else {
      nodes.Scene.position.z += delta;
      nodes.Scene.position.x = -6;
      nodes.Scene.rotation.y = 0;
    }
    mixer.update(delta);
  });

  return (
    <group ref={group}>
      <primitive object={nodes.Scene} scale={0.4} position={[-7, 2, 0]} />
    </group>
  );
}
