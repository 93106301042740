// Auto-generated by https://github.com/react-spring/gltfjsx

import * as THREE from "three";
import React, { useState, useRef, useEffect } from "react";
import { useThree, useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";


export default function Model({ ...props }) {
  const { nodes, animations } = useLoader(GLTFLoader, "/Dino_Small.glb");
  const [mixer] = useState(() => new THREE.AnimationMixer(nodes.Scene));
  const group = useRef();

  useEffect(() => void mixer.clipAction(animations[3], group.current).play(), []);

  useFrame((state, delta) => {
    
    if (nodes.Scene.position.z > 15) {
      nodes.Scene.position.z = -11
      nodes.Scene.position.x *= -1
    }
    nodes.Scene.position.z += delta
    mixer.update(delta);
  });

  return (
    <group ref={group}>
      <primitive object={nodes.Scene} scale={0.4} position={[3, -3, -4]} />
    </group>
  );
}
